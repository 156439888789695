<template>
  <footer class="footer mt-5">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container">
        <!-- <div class="subsccribe-item">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="subscribe-item">
                <div class="section-title">
                  <h2>Sign Up For Newsletter</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="subscribe-item">
                <form class="newsletter-form">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter your email"
                    autocomplete="off"
                  />
                  <button class="btn cmn-btn disabled" type="submit">
                    <i class="fas fa-paper-plane mr-2"></i>Subscribe Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row">

           <div class="col-lg-3 col-md-6">
            <div class="social-icon text-center footer-social-icon">
              <h3 class="footer-title font-weight-bold">
                {{ $t("Footer.SOCIAL") }}
              </h3>
              <ul>
                <li>
                  <a href="https://www.facebook.com/eparchemin" target="_blank"
                    ><i class="fab fa-facebook"></i
                  ></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/eparchemin" target="_blank"
                    ><i class="fab fa-instagram"></i
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/eparchemin/"
                    target="_blank"
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </li>
                <!-- <li>
                  <a href="#" target="_blank"
                    ><i class="fab fa-twitter"></i>
                  </a>
                </li> -->
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCjPA7lt-m9W74JcA39bMR-Q"
                    target="_blank"
                    ><i class="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
              <div class="col-12 mx-auto">
                <router-link class="btn btn-primary bt-lg mt-3 mb-5" :to="{name:'InitDon'}">
                  <span class="fas fa-donate"></span>
                 <b> {{ $t("Footer.MAKE_DONATION") }}</b>
                </router-link>
              </div>
            </div>
            
          </div>
          <div class="col-lg-5 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-about">
              <h2 class="footer-title text-center">{{ $t("Footer.ABOUT_US") }}</h2>
              <div class="footer-about-content mx-auto">
                <p class="text-justify">
                  {{ $t("Footer.ABOUT_US_DESC") }}
                </p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <!-- <div class="col-lg-2 col-md-6"> -->
          <!-- Footer Widget -->
          <!-- /Footer Widget -->
          <!-- </div> -->
          <div class="col-lg-4 col-md-6 contact-email">
            <!-- Footer Widget -->
            <h2 class="footer-title font-weight-bold">
              {{ $t("Footer.CONTACT_US") }}
            </h2>
            <ul class="contact-link">
              <li class="mb-3 ">
                {{ $t("Footer.QUESTION") }}<br />
                <i class="fa fa-envelope"></i>
                <a
                  class="ml-2 font-weight-bold"
                  href="mailto:contact@eparchemin.com"
                  >contact@eparchemin.com</a
                >
              </li>
              <li>
                {{ $t("Footer.COLLABORATION") }}<br />
                <i class="fa fa-envelope"></i>
                <a
                  class="ml-2 font-weight-bold"
                  href="mailto:collaboration@eparchemin.com"
                  >collaboration@eparchemin.com</a
                >
              </li>
            </ul>

            <!-- /Footer Widget -->
          </div>
         
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-12">
              <!-- <div class="copyright-text text-left">
                <p>Terms - Privacy Policy & Safety - Send feedback</p>
              </div> -->
            </div>
            <div class="col-md-6 col-12">
              <div class="copyright-text text-center">
                <p>
                  <label for="language"
                    ><i class="fas fa-globe"></i> Language:</label
                  >
                  <select id="language">
                    <option
                      :selected="$store.state.lang === 'fr'"
                      @click="changeLanguage('fr')"
                      >fr</option
                    >
                    <option
                      :selected="$store.state.lang === 'en'"
                      @click="changeLanguage('en')"
                      ><i class="fas fa-flag"></i> en</option
                    >
                  </select>
                </p>
                <p>&copy; {{ getFullYear }} eparchemin. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
<script>
export default {
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$store.commit("changeLanguage", lang);
    },
  },
};
</script>

<style scoped>
.footer-social-icon {
  font-size: 30px;
}

.contact-link {
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}

.contact-link a {
  color: #ff9c27;
}

@media only screen and (max-width: 750px) {
  .contact-email {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .contact-email h2 {
    text-align: center !important;
  }

  .footer-about h2 {
    text-align: center !important;
  }
}
</style>
