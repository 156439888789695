<template lang="">
    <div class="container">
        <div class="">
            <h2>En attente du mail de recuperation de votre mot de passe</h2>
            <p>
                Vous avez oublie votre mot de passe?
                Ne vous inquietez pas! ca arrive!<br>
                Vous allez recuperer votre compte dans quelques instant veillez consulter votre boite mail.
            </p>
            <router-link class="m-3 " :to="{name: 'Login'}">Login ?</router-link> <br>
            <p><button class="btn btn-sm btn-success" @click.prevent="emitRetryRequest()">Vous n'avez pas recu de mail?</button></p>
        </div>
    </div>
</template>

<script>
export default{
    methods: {
        emitRetryRequest () {
            this.$emit('retryRequest')
        }
    }
}
</script>