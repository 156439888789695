const { default: jwtDecode } = require("jwt-decode");

<template>
  <div class="main-wrapper">
    <router-view></router-view>
  </div>
</template>
<script>
import jwtDecode from "jwt-decode";
export default {
  beforeCreate() {
    this.$nextTick(function() {
      const refresh = localStorage.getItem("refresh");
      if (refresh!==null && jwtDecode(refresh).exp * 1000 > new Date().getTime())
        this.$store.dispatch("refreshToken");
      else this.$store.dispatch("logout");
    });
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.getters.loggedIn) {
        this.$store.dispatch("loadUserInfos");
      }
    });
  },
};
</script>
