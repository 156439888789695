<template>
  <div
    class="card border"
    :style="tooltipStyle"
    @mouseover="controlDisplay($event)"
  >
    <div :class="dir ? 'message-bull-left' : 'message-bull-right'">
      <h3 class="pb-0 mb-0 mt-2 text-center font-weight-bold font-italic">
        {{ course.title }}
      </h3>
      <div class="card-body">
        <div v-html="controlDescription(course.description)"></div>
      </div>
      <div class="card-footer">
        <h6 class="text-center">
          {{ numberFormat(course.newPrice > 0 ? course.newPrice : course.price)
          }}<strong>{{ course.currency }}</strong>
        </h6>
        <div class="d-flex justify-content-between">
          <router-link
            class="btn btn-success mr-1 btn-sm w-100"
            :to="{
              name: 'ShowCourseDetails',
              params: { idCourse: course.slug },
            }"
          >
            <span class="fa fa-eye"></span> Consulter</router-link
          >
        </div>
        <div className="row mt-2 mx-auto">
          <!-- <button className="btn btn-info btn-sm">Offrir</button> -->
          <router-link
            :to="{ name: 'InitPayment', params: { idCourse: course.slug } }"
            class="btn btn-info btn-sm w-100"
            ><span class="ml-1"
              ><span class="fa fa-coins"></span> Payer</span
            ></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from "numeral";
export default {
  props: {
    tooltipStyle: Object,
    course: Object,
    dir: Boolean,
  },
  methods: {
    numberFormat(n) {
      return numeral(n).format("0,0");
    },

    controlDescription(description) {
      if (description.length > 100) {
        return description.substr(0, 100) + "...";
      }
    },
    controlDisplay(event) {
      var courseId = "aricle-" + this.course.id;
      var tooltip = event.target;

      var mainWrapper = document.querySelector("body");

      var rect = tooltip.getBoundingClientRect();
      console.log("values: ", rect.top, rect.left, rect.bottom);
      console.log(mainWrapper);
      mainWrapper.onscroll = function () {
        console.log("Scroll");

        var tooltipParent = document.getElementById(courseId);
        var parentRect = tooltipParent.getBoundingClientRect();
        console.log("parent top", parentRect.top);

        if (rect.top > parentRect.bottom || rect.bottom < parentRect.top) {
          tooltip.style.display = "none";
          console.log("close");
          mainWrapper.onscroll = null;
        }
      };

      // var parent
    },
  },
};
</script>
<style scoped>
.message-bull-left,
.message-bull-right {
  position: relative;
}
.message-bull-left:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10%;
  width: 0;
  height: 0;
  border: 29px solid transparent;
  border-right-color: white;
  box-shadow: 0 1px silver;
  border-left: 0;
  border-bottom: 0;
  margin-top: -14.5px;
  margin-left: -29px;
}
.message-bull-right {
  margin-right: 0;
}
.message-bull-right:after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%;
  width: 0;
  height: 0;
  border: 29px solid transparent;
  border-left-color: white;
  box-shadow: 0 1px silver;
  border-right: 0;
  border-bottom: 0;
  margin-top: -14.5px;
  margin-right: -29px;
}
</style>
