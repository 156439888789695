import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/personal/Dashboard.vue";
import PasswordRecovery from "../views/auth/PasswordRecovery.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import ProfileSettings from "../views/personal/ProfileSettings";
import Login from "../views/auth/Login.vue";
import jwtDecode from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { auth: false },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: { auth: false },
  },{
    path: "/init-don/",
    name: "InitDon",
    component: () => import("../views/payment/InitDon.vue"),
    meta: { auth: false },
  },

  // AUTH
  {
    path: "/login",
    component: Login,
    name: "Login",
    meta: { auth: false },
  },
  {
    path: "/logout",
    component: () => import("../views/auth/Logout.vue"),
    name: "Logout",
    meta: { auth: false },
  },
  {
    path: "/register",
    component: () => import("../views/auth/Register.vue"),
    name: "Register",
    meta: { auth: false },
  },
  {
    path: "/passwordrecovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
    meta: { auth: false },
  },
  {
    path: "/auth/password-reset/:uidb64/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { auth: false },
  },
  {
    path: "/auth/email-verify/:token",
    name: "EailVerify",
    component: () => import("../views/auth/EmailVerify.vue"),
  },

  // /AUTH
  // PERSONAL
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    meta: { auth: true },
  },
  {
    path: "/profile-settings",
    component: ProfileSettings,
    name: "ProfileSettings",
    meta: { auth: true },
  },
  {
    path: "/earnings",
    component: () => import("@/views/personal/Earnings.vue"),
    name: "Earnings",
    meta: { auth: true },
  },
  // /PERSONAL

  // TEACHING
  {
    path: "/courses/:idCourse/",
    component: () => import("../views/teaching/ShowCourse.vue"),
    name: "ShowCourse",
    meta: { auth: true },
    props: true,
  },
  {
    path: "/courses/input",
    name: "CoursesInput",
    component: () => import("../views/teaching/InputCourse.vue"),
    meta: { auth: true, groups: ["admin"] },
  },
  {
    path: "/courses/show-course-category-:idCat",
    name: "ShowCourseCategory",
    component: () => import("../views/teaching/ShowCourseCategory.vue"),
    meta: { auth: false },
    props: true,
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("../views/teaching/Courses.vue"),
    meta: { auth: false },
  },
  {
    path: "/courses/dt/:idCourse/",
    name: "ShowCourseDetails",
    component: () => import("../views/teaching/ShowCourseDetails.vue"),
    meta: { auth: false },
    props: true,
  },
  // /TEACHING

  // PAYMENT
  {
    path: "/payment-status/:token",
    name: "AfterPayment",
    component: () => import("../views/payment/AfterPayment.vue"),
    meta: { auth: true },
  },
  {
    path: "/payment-init/:idCourse",
    name: "InitPayment",
    component: () => import("../views/payment/InitPayment.vue"),
    meta: { auth: true },
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Testview.vue"),
    meta: { auth: false },
  },
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: () => import("../views/errors/404.vue"),
    meta: { auth: false },
  },
];

const router = createRouter({
  scrollBehavior: function(to) {
    if (to.hash) {
      return { el: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const authRoutes = ["Register", "Login", "PasswordRecovery"];

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem("access");
  const isAuthenticated =
    accessToken && jwtDecode(accessToken).exp * 1000 > new Date().getTime();

  let userGroup = "",
    strUser = localStorage.getItem("srtingifiedUser");
  if (strUser != null) userGroup = JSON.parse(strUser).groups;

  if (
    authRoutes.includes(to.name) &&
    isAuthenticated &&
    (userGroup == "student" || userGroup == "teacher")
  )
    next({ name: "Dashboard" });
  if (authRoutes.includes(to.name) && isAuthenticated) {
    next({ name: "Home" });
  }

  if (to.meta.auth && !isAuthenticated) {
    localStorage.setItem("nextUrl", JSON.stringify(to));
    next({ name: "Login" });
  }

  if (to.meta.groups && to.meta.groups.indexOf(userGroup) == -1) {
    next({ name: "NotFound", params: { pathMatch: "not-found" } });
  }
  if (
    to.name === "Dashboard" &&
    userGroup !== "student" &&
    userGroup !== "teacher"
  ) {
    next({ name: "NotFound", params: { pathMatch: "not-found" } });
  }
  // TODO ajouter la protection par role
  next();
});

export default router;
