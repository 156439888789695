<template>
  <footer class="footer mb-0">
    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-4 col-12 col-sm-12">
              <ul class="contact-link">
                <li>
                  <i class="fa fa-envelope"></i>
                  <a
                    class="ml-2 font-weight-bold"
                    href="mailto:contact@eparchemin.com"
                    >contact@eparchemin.com</a
                  >
                </li>
              </ul>
              <div class="col-12 d-flex justify-content-start p-0 mr-0">
                <router-link
                  class="btn btn-primary bt-lg mt-3"
                  :to="{name:'InitDon'}"
                >
                  <span class="fas fa-donate"></span>
                  {{ $t("Footer.MAKE_DONATION") }}
                </router-link>
              </div>
            </div>
            <div class="col-md-4 col-12 pb-3">
              <div class="social-icon text-center footer-social-icon">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/eparchemin"
                      target="_blank"
                      ><i class="fab fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/eparchemin"
                      target="_blank"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/eparchemin/"
                      target="_blank"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                  <!-- <li>
                  <a href="#" target="_blank"
                    ><i class="fab fa-twitter"></i>
                  </a>
                </li> -->
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCjPA7lt-m9W74JcA39bMR-Q"
                      target="_blank"
                      ><i class="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="copyright-text text-center">
                <p>
                  <label for="language"
                    ><i class="fas fa-globe"></i> Language:
                  </label>
                  <select id="language">
                    <option
                      :selected="$store.state.lang === 'fr'"
                      @click="changeLanguage('fr')"
                    >
                      fr
                    </option>
                    <option
                      :selected="$store.state.lang === 'en'"
                      @click="changeLanguage('en')"
                    >
                      en
                    </option>
                  </select>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-text text-center pb-4 font-weight-bold">
          <p>&copy; {{ getFullYear }} eparchemin. All rights reserved.</p>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
<script>
export default {
  computed: {
    getFullYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$store.commit("changeLanguage", lang);
    },
  },
};
</script>

<style scoped>
.footer-social-icon {
  font-size: 30px;
}

.contact-link {
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}

.contact-link a {
  color: #ff9c27;
}

@media only screen and (max-width: 750px) {
  .footer {
    margin-top: auto;
    top: 20vh;
  }
  .contact-email {
    margin-left: 30px;
    margin-bottom: 20px;
  }

  .contact-email h2 {
    text-align: center !important;
  }

  .footer-about h2 {
    text-align: center !important;
  }

  .contact-link {
    text-align: center !important;
  }
}
</style>
