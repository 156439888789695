<template>
  <div class="main-wrapper">
    <div class="bg-pattern-style">
      <div class="content">
        <div class="account-content">
          <div class="account-box">
            <div class="login-right">
              <img
                :src="require('@/assets/img/logos/logo.png')"
                class="img-fluid auth-img"
                alt="Logo"
              />
              <div class="login-header">
                <h3>{{ $t("ResetPassword.CHANGEMENT_DE_MOT_DE_PASSE") }}</h3>
              </div>
              <form>
                <div v-if="expiredLink" class="alert alert-danger">
                  <h4 class="text-danger">
                    {{ $t("ResetPassword.LIEN_INVALIDE") }}
                  </h4>
                  <router-link :to="{ name: 'PasswordRecovery' }">{{
                    $t("ResetPassword.REDEMANDEZ_UN_AUTRE_LIEN")
                  }}</router-link>
                </div>
                <div class="form-group">
                  <label class="form-control-label">{{
                    $t("ProfileSettings.NOUVEAU_MOT_DE_PASSE")
                  }}</label>
                  <input
                    type="password"
                    v-model="newPassword"
                    class="form-control"
                    :class="getPasswordClass"
                    :placeholder="
                      $t('ProfileSettings.NOUVEAU_MOT_DE_PASSE') + '...'
                    "
                  />
                  <span class="invalid-feedback">{{
                    $t(
                      "RegisterPage.PASSWORD_MUST_CONTAIN_AT_LEAST_3_CHARACTERS"
                    )
                  }}</span>
                </div>
                <div class="form-group">
                  <label class="form-control-label">{{
                    $t("RegisterPage.CORFIRM_PASSWORD")
                  }}</label>
                  <input
                    type="password"
                    v-model="confirmPassword"
                    class="form-control"
                    :class="getConfirmPasswordClass"
                    :placeholder="$t('RegisterPage.CORFIRM_PASSWORD') + '...'"
                  />
                  <span class="invalid-feedback">{{
                    $t("RegisterPage.MOT_DE_PASSES_DIFFERENTS")
                  }}</span>
                </div>
                <button
                  class="btn btn-primary login-btn"
                  @click.prevent="changePassword"
                  type="submit"
                  :disabled="
                    newPassword.length < 3 || newPassword != confirmPassword
                  "
                >
                  {{ $t("ResetPassword.APPLIQUER") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      expiredLink: false,
    };
  },
  methods: {
    changePassword() {
      this.$http
        .patch("/auth/complete-reset-password", {
          newPassword: this.newPassword,
          token: this.$route.params.token,
          uidb64: this.$route.params.uidb64,
        })
        .then((response) => {
          if (response == undefined) {
            this.$toast.error(this.$t("ResetPassword.LIEN_INVALIDE"));
            this.expiredLink = true;
          } else {
            this.$toast.success(this.$t("ResetPassword.MOT_DE_PASSE_CHANGE"));
            this.$router.push({ name: "Login" });
          }
          this.newPassword = "";
          this.confirmPassword = "";
        })
        .catch(() => {
          this.$toast.error(this.$t("FLASH.UNE_ERREUR_EST_SURVENNU"));
          this.newPassword = "";
          this.confirmPassword = "";
        });
    },
  },
  computed: {
    getPasswordClass() {
      if (this.newPassword.length == 0) return "";
      if (this.newPassword.length < 3) return "is-invalid";
      return "is-valid";
    },
    getConfirmPasswordClass() {
      if (this.confirmPassword.length == 0) {
        return "";
      }
      if (this.confirmPassword.length !== this.newPassword.length) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
  },
  beforeMount() {
    this.$http.get(
      `/auth/password-reset/${this.$route.params.uidb64}/${this.$route.params.token}/`
    );
  },
};
</script>
