{
  "FLASH": {
    "UNE_ERREUR_EST_SURVENNU": "Une erreur est survennu, veillez reessayer plus tard!",
    "SUCCESS": "Success!"
  },
  "Login": {
    "IDENTIFIANTS_INCORRECTS": "Identifiants incorrectes!",
    "EMAIL_INVALIDE": "Véillez entrer une adresse mail valide",
    "MOT_DE_PASSE": "Mot de passe",
    "SE_CONNECTER": "Se connecter",
    "MOT_DE_PASSE_OUBLIE": "Mot de passe oublié?",
    "PAS_DE_COMPTE": "Vous n'avez pas de compte?",
    "CREER_COMPTE": "Creer un compte",
    "MOT_DE_PASSE_AU_MOINS_3": "Au moins 3 caracteres",
    "LOGIN_SUCCESS": "Bienvennu!"
  },
  "RegisterPage": {
    "ALREADY_HAVE_AN_ACCOUNT": "Vous avez déjà un compte ?",
    "FIRST_NAME": "Prénom",
    "CREATE_ACCOUNT": "Créer un compte",
    "CORFIRM_PASSWORD": "Confirmer le mot de passe",
    "PASSWORD": "Mot de passe",
    "EMAIL_ADDRESS": "Adresse e-mail ",
    "LAST_NAME": "Nom de Famille ",
    "THE_FIRST_NAME_MUST_HAVE_MUST_AT_LEAST_3_CHARACTERS": "Le prénom doit avoir au moins 3 caractères",
    "THE_LAST_NAME_MUST_HAVE_MUST_AT_LEAST_3_CHARACTERS": "Le nom de famille doit avoir au moins 3 caractères ",
    "THE_EMAIL_IS_INVALID": "L'adresse mail est invalid",
    "PASSWORD_MUST_CONTAIN_AT_LEAST_3_CHARACTERS": "Le mot de passe doit contenir au moins au 3 caractères",
    "MOT_DE_PASSES_DIFFERENTS": "Les deux mots de passe ne sont pas identiques",
    "ADRESS_MAIL_EXISTANT": "l'adresse mail dejà existante"
  },
  "PasswordRecovery": {
    "MOT_DE_PASSE_OUBLIE": "Mot de passe oublié?",
    "CLIQUEZ_ICI": "cliquez ici",
    "LIEN_DE_CHANGEMENT_DE_MOT_DE_PASSE_ENVOYE": "Lien de changement de mot de passe envoyé",
    "ENTREZ_VOTRE_ADRESSE_EMAIL": "Entrez votre adresse email pour recevoir le lien de modification de votre mot de passe",
    "ENVOYER_LA_REQUETTE": "Envoyer la requette"
  },
  "ResetPassword": {
    "CHANGEMENT_DE_MOT_DE_PASSE": "Reinitialisation du mot de passe",
    "APPLIQUER": "Appliquer",
    "LIEN_INVALIDE": "Lien expiré!",
    "MOT_DE_PASSE_CHANGE": "Mot de passe changé avec success! Connectez vous maintenant!",
    "REDEMANDEZ_UN_AUTRE_LIEN": "Redémander un autre lien"
  },
  "Dashboard": {
    "CATEGORIES": "Catégories",
    "GAIN": "Gain",
    "LISTE_DE_COURS": "Liste de cours",
    "PRIX": "Prix",
    "COURS": "Cours",
    "INSCRITS": "Inscrits"
  },
  "StudentDashboard": {
    "COURS": "Cours",
    "PROGRESSION": "Progréssion",
    "VIDEOS": "vidéos",
    "DEBUT": "Début",
    "FIN": "Fin",
    "ACCEDER": "Accéder",
    "CERTIFICAT": "Certificat",
    "PAS_DE_COURS": "Vous n'avez pas de formation actuellement!",
    "CONSULTEZ_LES_COURS": "Consultez toutes les formations disponnibles ici!"
  },
  "ProfileSettings": {
    "PROFIL_ENSEIGNANT": "Profil proffessionel",
    "CHARGER_IMAGE": "charger une image",
    "MON_PROFILE": "Mon profile",
    "TEL": "Numéro de téléphone",
    "VILLE": "Ville",
    "PAYS": "Pays",
    "CHANGER_MOT_DE_PASSE": "Changer le mot de passe",
    "ANCIEN_MOT_DE_PASSE": "Ancien mot de passe",
    "APPLIQUER": "Appliquer les changements",
    "PHOTO_DE_PROFIL": "Photo de profil",
    "PRECISION_IMAGES_VALIDES": "JPG, GIF ou PNG autorisés. Taille maximale de 2 Mo",
    "NOUVEAU_MOT_DE_PASSE": "Nouveau mot de passe",
    "MOT_DE_PASSE_CHANGE": "Mot de passe changé avec success!"
  },
  "PartCourseContent": {
    "VIDEO_PRECEDENTE": "Vidéo précédente",
    "VIDEO_SUIVANTE": "Vidéo suivante",
    "RESSOURCE": "Resource",
    "PAS_DE_CONTENNU": "Pas de contennu pour le moment!"
  },
  "ShowCourse": {
    "TABLE_DE_MATIERES": "Table de matieres",
    "DETAILS_DU_COURS": "Détails du cours"
  },
  "ShowCourseDetails": {
    "AJOUTER_PARTIE": "Ajouter une partie",
    "A_PROPOS_ENSEIGNANT": "A propos de l'enseignant",
    "TITRE": "Titre",
    "IMAGE_DU_COURS": "Image du cours",
    "CHARGER": "Charger",
    "ANNULER": "Annuler",
    "APPLIQUER": "Appliquer",
    "TABLE_CONTENT": "Table des matières"
  },
  "ArticleCourseComponent": {
    "PLUS_INFOS": "Cliquez pour plus d'infos..."
  },
  "HomePage": {
    "TITRE_DE_PRESENTATION_GENERALE": "Acquérez dès aujourd’hui une compétence pour démarrer un Business.",
    "APPRENEZ_DEPUIS_CHEZ_VOUS": "Apprenez depuis chez vous sans stress à votre rythme et à vos heures.",
    "PROGRAMMES_DE_COURS": "Programmes de cours",
    "ETUDIANTS_GLOBAL": "Etudiants inscrits",
    "FORMATEURS_GLOBAL": "Formateurs professionnels",
    "NOUVELLES_FORMATIONS": "Retrouvez aussi les nouvelles formations.",
    "FORMATIONS_POPULAIRES": "Retrouvez les formations populaires ici.",
    "VIEW_ALL_COURSE": "Voir tous les cours",
    "SEARCH_NOW": "Rechercher",
    "OVER": "Environ",
    "A_PROPOS": "A Propos de Nous",
    "A_PROPOS_DESC": "Eparchemin en Bref",
    "COURSES": "Cours",
    "POPULAR_COURSES": "Cours Populaires",
    "NEW_COURSES": "Nouveaux Cours",
    "PAYMENT_MEANS": "Moyens de Paiements",
    "PAYMENT_MEANS_DESC": "Nous acceptons les paiments par",
    "INTERNATIONAL_PAYMENT": "Paiement International",
    "PRICE_AND_PARTNER": "Partenaires",
    "PRICE_AND_PARTNER_DESC": "Notre partenaire",
    "PRICE_WE_EARNED": "Le prix que nous avons déjà gagné",
    "OUR_PARTNERS": "Notre partenaire"
  },
  "Footer": {
    "ABOUT_US": "A Propos de Nous",
    "ABOUT_US_DESC": "Eparchemin est une plateforme d'apprentissage en ligne. Nous proposons des formations de haute qualité en agriculture et en élevage, en fabrication du lait de toilette, fabrication du savon, fabrication du détergent en poudre, en entrepreneuriat. Vous apprenez à votre rythme sur la plateforme 100% digitale depuis chez vous. ",
    "CONTACT_US": "Nous Contacter ",
    "QUESTION": "Pour des questions diverses",
    "COLLABORATION": "Pour une collaboration",
    "SOCIAL": "Nous Sommes sociable",
    "TECHNICAL_ISSUE": "Pour tout problème technique",
    "MAKE_DONATION": "Faire une donation"
  },
  "Navbar": {
    "CURRENCY": "Devise",
    "COURSES": "Cours"
  },
  "PaymentPage": {
    "PAYMENT_SUMMARY": "Resumé de votre payment",
    "TEACHER": "Enseignant",
    "CANCEL": "Annuler",
    "APPLY": "Appliquer",
    "PAY_NOW": "Procéder au paiement",
    "PAYMENT_METHOD": "Moyen de paiement",
    "PAYMENT_METHOD_DON": "Choisissez votre  moyen de paiement",
    "BILLING_INFORMATION": "Billing information"
  },
  "DonationPage": {
    "COMMENT": "Vous pouvez enter ce aue vous desirex par exemple vos informations pour vous remercier si vous le voulew bien",
    "INVALID_AMOUNT": "Le montant doit etre compri entre 100 et 1500000"
  }
}