<template>
  <div class="main-wrapper">
    <div class="bg-pattern-style">
      <div class="content">
        <div class="account-content">
          <div class="account-box">
            <div v-if="!waitinForLink" class="login-right">
              <img
                :src="require('@/assets/img/logos/logo.png')"
                class="img-fluid auth-img"
                alt="Logo"
              />
              <div class="login-header">
                <h3>{{ $t("PasswordRecovery.MOT_DE_PASSE_OUBLIE") }}</h3>
                <p class="text-muted">
                  {{ $t("PasswordRecovery.ENTREZ_VOTRE_ADRESSE_EMAIL") }}
                </p>
              </div>
              <form class="mb-2">
                <div class="form-group">
                  <label class="form-control-label">{{
                    $t("RegisterPage.EMAIL_ADDRESS")
                  }}</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    :class="getEmailClass"
                    :placeholder="$t('RegisterPage.EMAIL_ADDRESS') + '...'"
                  />
                  <span class="invalid-feedback">{{
                    $t("RegisterPage.THE_EMAIL_IS_INVALID")
                  }}</span>
                </div>
                <button
                  class="mt-1 btn btn-primary login-btn"
                  @click.prevent="resetPassword"
                  type="submit"
                >
                  {{ $t("PasswordRecovery.ENVOYER_LA_REQUETTE") }}
                </button>
              </form>
              <router-link class="m-3 " :to="{ name: 'Login' }"
                >Login ?</router-link
              >
            </div>
            <WaitingForLink
              @retryRequest="onRetryRequest()"
              v-if="waitinForLink"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaitingForLink from "@/components/WaitingForLink.vue";
export default {
  name: "PasswordRecovery",
  components: { WaitingForLink },
  data() {
    return {
      email: "",
      waitinForLink: false,
    };
  },
  methods: {
    resetPassword() {
      if (this.validEmail) {
        this.$http
          .post("/auth/request-reset-password-link/", { email: this.email })
          .then(() => {
            this.$toast.success(
              this.$t(
                "PasswordRecovery.LIEN_DE_CHANGEMENT_DE_MOT_DE_PASSE_ENVOYE"
              )
            );
            this.email = "";
            this.waitinForLink = true;
          })
          .catch(() => {
            this.$toast.error(this.$t("FLASH.UNE_ERREUR_EST_SURVENNU"));
          });
      }
    },
    onRetryRequest() {
      this.waitinForLink = false;
    },
  },
  computed: {
    getEmailClass() {
      if (this.email.length == 0) return "";
      if (this.email.length > 0 && !this.validEmail) return "is-invalid";

      return "is-valid";
    },
    validEmail() {
      return this.$store.state.emailRegex.test(this.email);
    },
  },
};
</script>

<style scoped></style>
