<template>
  <Navbar />
  <!-- Breadcrumb -->
  <div class="breadcrumb-bar">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-12 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="index-2.html">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>
          <h2 class="breadcrumb-title">Dashboard</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->

  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
          <!-- Sidebar -->
          <UserSidebar />
          <!-- /Sidebar -->
        </div>

        <div v-if="isStudent === false" class="col-md-7 col-lg-8 col-xl-9">
          <div v-if="courses !== null">
            <div class="row">
              <div class="col-md-12 col-lg-4 dash-board-list blue">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-book"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>{{ courses.length }}</h3>
                    <h6>Courses</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 dash-board-list yellow">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-users"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>{{ studentsCount }}</h3>
                    <h6>{{ studentsCount != 1 ? "Students" : "Student" }}</h6>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-4 dash-board-list pink">
                <div class="dash-widget">
                  <div class="circle-bar">
                    <div class="icon-col">
                      <i class="fas fa-coins"></i>
                    </div>
                  </div>
                  <div class="dash-widget-info">
                    <h3>{{ formatPrice(totalGain) }} {{ currency }}</h3>
                    <h6>{{ $t("Dashboard.GAIN") }}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h4 class="mb-4">{{ $t("Dashboard.LISTE_DE_COURS") }}</h4>
                <div class="card card-table">
                  <div class="card-body">
                    <div class="table-responsive">
                      <Datatable
                        :perPage="6"
                        :className="'table-hover table-center mb-0'"
                        :columns="getColumns()"
                        :rows="getRows"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <StudentDashboard
          class="col-md-7 col-lg-8 col-xl-9"
          v-if="isStudent === true"
        />
      </div>
    </div>
    <DashboardFooter />
  </div>
  <!-- /Page Content -->
</template>

<script>
import Navbar from "@/components/layouts/Navbar";
import DashboardFooter from "@/components/layouts/DashboardFooter";
import jwtDecode from "jwt-decode";
import { APIUrl } from "@/http";
import numeral from "numeral";
import UserSidebar from "@/components/shared/personal/UserSidebar.vue";
import StudentDashboard from "@/components/shared/personal/StudentDashboard.vue";
import Datatable from "../../components/shared/tools/Datatable.vue";

export default {
  data() {
    return {
      courses: null,
      api: APIUrl,
      isStudent: null,
      filterKey: "",
      totalGain: 0,
      currency: "XAF",
    };
  },
  components: {
    Navbar,
    UserSidebar,
    StudentDashboard,
    Datatable,
    DashboardFooter,
  },
  methods: {
    getColumns() {
      return [
        {
          property: "title",
          title: this.$t("Dashboard.COURS"),
          direction: "up",
          filterable: true,
        },
        {
          property: "price",
          title: this.$t("Dashboard.PRIX"),
          direction: "up",
          filterable: true,
        },
        {
          property: "studentsNumber",
          title: this.$t("Dashboard.INSCRITS"),
          direction: "up",
          filterable: true,
        },
        {
          property: "action",
          title: "ACTION",
          direction: "up",
          filterable: false,
        },
      ];
    },
    async loadDashboard() {
      const token = localStorage.getItem("access");
      if (token !== null) {
        if (
          this.$store.getters.userGroup == "teacher" &&
          this.courses === null
        ) {
          await this.$http
            .get(`/auth/teacher/course-list/${jwtDecode(token).user_id}/`)
            .then((response) => {
              this.courses = response.data;
              this.getTotalGain();
            });
        }
      }
    },
    formatPrice(p) {
      return numeral(p).format("0,0");
    },
    getTotalGain() {
      if (this.$store.getters.userGroup === "teacher")
        this.$http.get("/auth/teacher/total-gain").then((response) => {
          this.totalGain = response.data.amount;
          this.currency = response.data.currency;
        });
    },
  },
  beforeCreate() {
    this.$nextTick(function () {
      this.isStudent = this.$store.getters.userGroup == "student";
    });
  },
  beforeMount() {
    this.loadDashboard();
  },
  computed: {
    getRows() {
      let rows = this.courses.map((c) => {
        let mapped = c;
        mapped.price = this.formatPrice(c.price) + " " + c.currency;
        mapped.title = `
        <h2 class="table-avatar">
          <a
            href="courses/${c.id}/show-details"
            class="avatar avatar-sm mr-2"
            ><img
              class="avatar-img rounded-circle"
              src="${c.coursePicture.thumbnail}"
              alt="${c.title}"
          /></a>
          <a href="courses/${c.id}/show-details">${c.title}</a>
        </h2>
        `;
        mapped.action = `
        <a
            href="courses/${c.id}/show-details"
            class="btn btn-sm bg-info-light"
            ><i class="far fa-edit"></i> Edit</a>
          <a
            href="courses/${c.id}/show-details"
            class="btn ml-2 btn-sm bg-success-light"
            ><i class="fas fa-info-circle"></i>
            More</a
          >
        `;
        return mapped;
      });
      return rows;
    },
    studentsCount() {
      let n = 0;
      this.courses.forEach((c) => {
        n += c.studentsNumber;
      });
      return n;
    },
    getLogedUserFullName() {
      return (
        this.$store.state.logedUser.firstName +
        " " +
        this.$store.state.logedUser.lastName
      );
    },
  },
};
</script>
