<template lang="">
  <div>
    <div v-if="rows.length === 0" class="alert alert-danger text-center">
      Pas de contenu!
    </div>
    <table v-else class="table" :class="className">
      <tr>
        <th v-for="(column, i) in columns" :key="i">
          <div class="d-inline-block">{{ column.title }}</div>
          <div
            v-if="column.filterable && column.direction === 'up'"
            class="d-inline-block ml-4 btn-link"
          >
            <i
              @click="() => handleChangeDirection(column)"
              class="ml-auto fas fa-arrow-up"
            ></i>
          </div>
          <div
            v-if="column.filterable && column.direction === 'down'"
            class="d-inline-block ml-4 btn-link"
          >
            <i
              @click="() => handleChangeDirection(column)"
              class="ml-auto fas fa-arrow-down"
            ></i>
          </div>
        </th>
      </tr>
      <tr v-for="(row, i) in paginate" :key="i">
        <td
          v-for="(column, j) in columns"
          :key="j"
          v-html="row[column.property]"
        ></td>
      </tr>
    </table>
  </div>
  <div
    v-if="perPage < rows.length"
    class="d-flex justify-content-around border mt-1"
  >
    <button :disabled="page === 1" class="btn btn-sm btn-info" @click="prev">
      <i class="fas fa-arrow-left"></i>
    </button>
    <p>page {{ page }}/{{ totalPages }}</p>
    <button
      :disabled="page == totalPages"
      class="btn btn-sm btn-info"
      @click="next"
    >
      <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      filter: "",
      sortBy: null,
    };
  },

  props: {
    columns: Array,
    rows: Array,
    perPage: Number,
    className: String,
  },

  computed: {
    totalPages() {
      return Math.ceil(this.sortedData.length / this.perPage);
    },
    paginate() {
      let start = (this.page - 1) * this.perPage;
      if (start < 0) start = 1;
      let data = this.sortedData;
      return data.slice(start, start + this.perPage);
    },
    sortedData() {
      if (this.sortBy === null) return this.rows;
      const { property, direction } = this.sortBy;
      let data = this.rows;
      return data.sort(function(r1, r2) {
        if (direction === "up") {
          if (r1[property] < r2[property]) return -1;
          if (r1[property] > r2[property]) return 1;
          return 0;
        } else {
          if (r1[property] < r2[property]) return 1;
          if (r1[property] > r2[property]) return -1;
          return 0;
        }
      });
    },
  },

  methods: {
    handleChangeDirection(column) {
      if (column.direction === "up") column.direction = "down";
      else if (column.direction === "down") column.direction = "up";
      this.sortBy = column;
    },
    prev() {
      if (this.page > 1) this.page--;
    },
    next() {
      if (this.page < this.totalPages) this.page++;
    },
  },
};
</script>
<style lang=""></style>
