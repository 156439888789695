<template lang="">
  <div class="profile-sidebar">
    <div class="user-widget">
      <div class="pro-avatar">
        <img :src="userPictures.mediumSquareCrop" alt="User Image" />
      </div>
      <div class="rating">
        <i class="fas fa-star filled"></i>
        <i class="fas fa-star filled"></i>
        <i class="fas fa-star filled"></i>
        <i class="fas fa-star filled"></i>
        <i class="fas fa-star"></i>
      </div>
      <div class="user-info-cont">
        <h4 class="usr-name font-weight-bold">
          {{ user.firstName }} {{ user.lastName }}
        </h4>
        <!-- <p class="mentor-type">English Literature (M.A)</p> -->
      </div>
    </div>
    <div class="progress-bar-custom">
      <!-- <h6>Complete your profiles ></h6> -->
      <!-- <div class="pro-progress">
        <div class="tooltip-toggle" tabindex="0"></div>
        <div class="tooltip">80%</div>
      </div> -->
    </div>
    <div class="custom-sidebar-nav mt-5 pt-3">
      <ul>
        <li
          v-if="
            $store.getters.userGroup === 'student' ||
            $store.getters.userGroup === 'teacher'
          "
        >
          <router-link
            :to="{ name: 'Dashboard' }"
            :class="getNameRoute === 'Dashboard' ? 'active' : ''"
            ><i class="fas fa-home"></i>Dashboard
            <span><i class="fas fa-chevron-right"></i></span
          ></router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'ProfileSettings' }"
            :class="getNameRoute === 'ProfileSettings' ? 'active' : ''"
            ><i class="fas fa-user-cog"></i>Profile
            <span><i class="fas fa-chevron-right"></i></span
          ></router-link>
        </li>
        <li v-if="$store.getters.userGroup === 'teacher'">
          <router-link
            :to="{ name: 'Earnings' }"
            :class="getNameRoute === 'Earnings' ? 'active' : ''"
            ><i class="far fa-chart-bar"></i>Earnings
            <span><i class="fas fa-chevron-right"></i></span
          ></router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Logout' }"
            ><i class="fas fa-sign-out-alt"></i>Logout
            <span><i class="fas fa-chevron-right"></i></span
          ></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { APIUrl } from "../../../http";
export default {
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        profilePicture: {
          mediumSquareCrop: "",
          thumbnail: "",
          fullSize: "",
        },
      },
    };
  },
  props: {
    onChangeUserInfo: Boolean,
  },
  methods: {
    async loadUser() {
      const savedUser = localStorage.getItem("srtingifiedUser");
      if (!savedUser) {
        this.$store.dispatch("loadUserInfos").then(() => {
          this.user = JSON.parse(localStorage.getItem("srtingifiedUser"));
        });
      } else {
        this.user = JSON.parse(savedUser);
      }
    },
  },
  computed: {
    getNameRoute() {
      return this.$route.name;
    },
    userPictures() {
      if (!this.user.profilePicture.fullSize.includes("http")) {
        return {
          fullSize: APIUrl + this.user.profilePicture.fullSize,
          thumbnail: APIUrl + this.user.profilePicture.thumbnail,
          mediumSquareCrop: APIUrl + this.user.profilePicture.mediumSquareCrop,
        };
      }
      return this.user.profilePicture;
    },
  },
  watch: {
    onChangeUserInfo() {
      this.loadUser();
      this.$emit("userInfosReloaded");
    },
  },
  beforeMount() {
    this.loadUser();
  },
};
</script>
<style scoped>
@media only screen and (max-width: 750px) {
  .profile-sidebar {
    display: none !important;
  }
}
</style>
