<template>
  <div
    class="col-lg-3 col-md-4 col-sm-12 col-12 article"
    @mouseover.prevent="diplayDetailsOnTooltip()"
    @mouseleave="dismissDetailsOnTooltip()"
    :id="'aricle-' + course.id"
  >
    <router-link
      :to="{ name: 'ShowCourseDetails', params: { idCourse: course.slug } }"
    >
      <div class="popular-course">
        <div class="courses-head">
          <div class="courses-img-main">
            <img
              :src="pictures.mediumSquareCrop"
              alt=""
              class="img-fluid w-100"
            />
          </div>
          <div class="courses-aut-img">
            <img :src="userPictures.thumbnail" alt="" />
          </div>
        </div>
        <div class="courses-body">
          <div class="courses-ratings">
            <ul class="mb-1">
              <li><i class="fas fa-star checked"></i></li>
              <li><i class="fas fa-star checked"></i></li>
              <li><i class="fas fa-star checked"></i></li>
              <li><i class="fas fa-star checked"></i></li>
              <li><i class="fas fa-star not-checked"></i></li>
            </ul>
            <p class="mb-1">
              {{ course.teacher.firstName }} {{ course.teacher.lastName }}
            </p>
            <h4 class="mb-0">{{ transformedTititle }}</h4>
          </div>
        </div>
        <div class="courses-border"></div>
        <div class="courses-footer d-flex align-items-center">
          <div class="courses-count">
            <ul class="mb-0">
              <li>
                <i class="fas fa-user-graduate mr-1"></i>
                <!-- {{ course.studentsNumber }} -->
              </li>
            </ul>
          </div>
          <div class="courses-amt ml-auto">
            <h5 class="mb-0" v-if="course.newPrice == 0 && course.code == ''">
              {{ numberFormat(course.price) }}
              <strong>{{ course.currency }}</strong>
            </h5>
            <small
              v-if="course.newPrice > 0 && course.code != ''"
              class="text-through"
              >{{ numberFormat(course.price) }}
              <strong>{{ course.currency }}</strong></small
            >
            <h5 v-if="course.newPrice > 0 && course.code != ''">
              {{ numberFormat(course.newPrice) }}
              <strong>{{ course.currency }}</strong>
            </h5>
          </div>
        </div>
      </div>
    </router-link>
    <ArticleTooltip
      :course="course"
      v-if="displayTooltip"
      :tooltipStyle="tooltipStyle"
      :dir="directionTooltipBullLeft"
    />
  </div>
</template>
<script>
import numeral from "numeral";
import { APIUrl } from "../../../http";
import ArticleTooltip from "./ArticleTooltip.vue";
export default {
  components: { ArticleTooltip },
  name: "ArticleCourseComponent",
  props: {
    course: {
      id: Number,
      slug: String,
      title: String,
      description: String,
      price: Number,
      newPrice: {
        type: Number,
        default: 0,
      },
      currency: String,
      coursePicture: {
        fullSize: String,
        thumbnail: String,
        mediumSquareCrop: String,
      },
      teacher: {
        firstName: String,
        lastName: String,
        profilePicture: {
          mediumSquareCrop: String,
          fullSize: String,
          thumbnail: String,
        },
      },
    },
  },
  data() {
    return {
      accessible: null,
      activeCourseChecked: false,
      tooltipStyle: {},
      displayTooltip: false,
      directionTooltipBullLeft: true,
      loadedEnrolement: false,
    };
  },
  methods: {
    numberFormat(n) {
      return numeral(n).format("0,0");
    },
    diplayDetailsOnTooltip() {
      const rect = document
        .getElementById("aricle-" + this.course.id)
        .getBoundingClientRect();
      let leftTooltip = rect.width + rect.left;
      if (window.innerWidth - leftTooltip < 500) {
        this.directionTooltipBullLeft = false;
        leftTooltip = rect.left - rect.width + 35;
      } else {
        this.directionTooltipBullLeft = true;
      }
      let topTooltip = rect.top;
      if (topTooltip < 0) topTooltip = 94;
      this.tooltipStyle = {
        position: "fixed",
        display: "block",
        zIndex: 9999999999,
        width: rect.width - 25 + "px",
        minHeight: "300px",
        left: leftTooltip + "px",
        top: rect.top + "px",
      };
      this.displayTooltip = true;

      if (this.$store.getters.loggedIn) this.checkAccessUser();
      else {
        this.activeCourseChecked = true;
      }
    },
    strpos(haystack, needle, offset) {
      APIUrl;
      var i = (haystack + "").indexOf(needle, offset || 0);
      return i === -1 ? -1 : i;
    },
    dismissDetailsOnTooltip() {
      this.displayTooltip = false;
      this.tooltipStyle = {};
    },
    async checkAccessUser() {
      if (this.$)
        if (this.accessible === null)
          await this.$http
            .get(`/enrolment/check-user-course-access/${this.course.id}`)
            .then((response) => {
              this.accessible = response.data;
            });
    },
  },
  computed: {
    transformedTititle() {
      if (this.course.title.length > 35)
        return this.course.title.substring(0, 35) + "...";
      return this.course.title;
    },
    pictures() {
      // if (this.strpos(this.course.coursePicture.fullSize, APIUrl) === -1) {
      //   return {
      //     fullSize: APIUrl + this.course.coursePicture.fullSize,
      //     thumbnail: APIUrl + this.course.coursePicture.thumbnail,
      //     mediumSquareCrop: APIUrl + this.course.coursePicture.mediumSquareCrop,
      //   };
      // }
      if (!this.course.coursePicture.fullSize.includes("http")) {
        return {
          fullSize: APIUrl + this.course.coursePicture.fullSize,
          thumbnail: APIUrl + this.course.coursePicture.thumbnail,
          mediumSquareCrop: APIUrl + this.course.coursePicture.mediumSquareCrop,
        };
      }
      return this.course.coursePicture;
    },
    userPictures() {
      // if (
      //   this.strpos(this.course.teacher.profilePicture.fullSize, APIUrl) === -1
      // ) {
      //   return {
      //     fullSize: APIUrl + this.course.teacher.profilePicture.fullSize,
      //     thumbnail: APIUrl + this.course.teacher.profilePicture.thumbnail,
      //     mediumSquareCrop:
      //       APIUrl + this.course.teacher.profilePicture.mediumSquareCrop,
      //   };
      // }
      if (!this.course.coursePicture.fullSize.includes("http")) {
        return {
          fullSize: APIUrl + this.course.teacher.profilePicture.fullSize,
          thumbnail: APIUrl + this.course.teacher.profilePicture.thumbnail,
          mediumSquareCrop:
            APIUrl + this.course.teacher.profilePicture.mediumSquareCrop,
        };
      }
      return this.course.teacher.profilePicture;
    },
  },
};
</script>
<style scoped>
.text-through {
  text-decoration: line-through;
}
.article {
  position: relative;
  scroll-snap-align: start;
}
a,
a:hover {
  text-decoration: none;
}

.popular-course {
  background-color: rgba(243, 236, 236, 0.459);
  padding: 0 !important;
}
</style>
