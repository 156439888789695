import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import store from "./store";
import router from "./router";
import { getAPI } from "./http";
import VueAxios from "vue-axios";

// import "./assets/js/popper.min";
// import "./assets/js/script";
// import "./assets/js/owl.carousel.min";
// import "./assets/js/bootstrap.min"
import "./assets/css/style.css";

import Toaster from "@meforma/vue-toaster";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


createApp(App)
  .use(store)
  .use(Toaster)
  .use(VueAxios, getAPI)
  .use(router)
  .use(i18n)
  .mount("#app");

  Sentry.init({
    App,
    dsn: "https://31b10901e4884e04b1bd821a01b84dc2@o1048445.ingest.sentry.io/6037277",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["online", "https://eparchemin.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });
  