<template>
  <Navbar />
  <!-- Breadcrumb -->
  <div class="breadcrumb-bar">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-12 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->

              <li>
                <router-link to="/">Home <b>/</b></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ $t("ProfileSettings.MON_PROFILE") }}
              </li>
            </ol>
          </nav>
          <h2 class="breadcrumb-title"></h2>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->

  <!-- Page Content -->
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- Profile Sidebar -->
        <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
          <!-- Sidebar -->
          <UserSidebar
            :onChangeUserInfo="reloadUserInfos"
            @userInfosReloaded="() => onUserInfosReloaded()"
          />
          <!-- /Sidebar -->
        </div>
        <!-- /Profile Sidebar -->
        <div class="col-md-7 col-lg-8 col-xl-9">
          <div class="card">
            <div class="card-body">
              <!-- Profile Settings Form -->
              <form @submit.prevent="submit">
                <div class="row form-row">
                  <div class="col-12 col-md-12 border mb-3">
                    <h3>{{ $t("ProfileSettings.PHOTO_DE_PROFIL") }}</h3>
                    <div class="form-group">
                      <div class="change-avatar">
                        <div class="profile-img">
                          <img
                            :src="user.profilePicture.mediumSquareCrop"
                            alt="User Image"
                          />
                        </div>
                        <div class="upload-img">
                          <div class="change-photo-btn w-100">
                            <span
                              ><i class="fa fa-upload"></i
                              >{{ $t("ProfileSettings.CHARGER_IMAGE") }}
                              <img
                                v-if="loading === true"
                                :src="require('@/assets/img/loader.gif')"
                                alt=""
                                style="
                                  width: 80px;
                                  height: 80px;
                                  top: -20px;
                                  position: absolute;
                                "
                              />
                            </span>
                            <input
                              @change="loadImage"
                              accept="image/*"
                              type="file"
                              class="upload"
                            />
                          </div>
                          <small class="form-text text-muted">{{
                            $t("ProfileSettings.PRECISION_IMAGES_VALIDES")
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>{{ $t("RegisterPage.FIRST_NAME") }}:</label>
                      <input
                        v-model="user.firstName"
                        type="text"
                        class="form-control"
                        :class="getFirstNameClass"
                      />
                      <span class="invalid-feedback">{{
                        $t("Login.MOT_DE_PASSE_AU_MOINS_3")
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>{{ $t("RegisterPage.LAST_NAME") }}:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.lastName"
                        :class="getlastNameClass"
                      />
                      <span class="invalid-feedback">{{
                        $t("Login.MOT_DE_PASSE_AU_MOINS_3")
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>{{ $t("RegisterPage.EMAIL_ADDRESS") }}:</label>
                      <input
                        type="email"
                        class="form-control"
                        v-model="user.email"
                        :class="getEmailClass"
                      />
                      <span class="invalid-feedback">{{
                        $t("Login.EMAIL_INVALIDE")
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>{{ $t("ProfileSettings.TEL") }}:</label>
                      <input
                        type="text"
                        v-model="user.phone"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>{{ $t("ProfileSettings.VILLE") }}:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.city"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>{{ $t("ProfileSettings.PAYS") }}:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="user.country"
                      />
                    </div>
                  </div>
                </div>
                <div class="submit-section">
                  <button
                    type="submit"
                    :disabled="
                      user.lastName.length < 3 ||
                      user.firstName.length < 3 ||
                      !validEMail
                    "
                    class="btn btn-primary"
                  >
                    {{ $t("ProfileSettings.APPLIQUER") }}
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="isProfileUpdatable"
                    ></span>
                  </button>
                </div>
              </form>
              <!-- /Profile Settings Form -->
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <!-- change password Form -->
              <form @submit.prevent="changePassword()">
                <h3 class="mb-2">
                  {{ $t("ProfileSettings.CHANGER_MOT_DE_PASSE") }}
                </h3>
                <div class="row form-row">
                  <div class="col-6 form-group">
                    <label for="old-password" class="form-label"
                      >{{ $t("ProfileSettings.ANCIEN_MOT_DE_PASSE") }}
                      <span class="text-danger">*</span>:</label
                    >
                    <input
                      type="password"
                      id="old-password"
                      class="form-control"
                      v-model="oldPassword"
                      :class="getOldPasswordClass"
                      :placeholder="
                        $t('ProfileSettings.ANCIEN_MOT_DE_PASSE') + '...'
                      "
                    />
                    <span class="invalid-feedback">{{
                      $t(
                        "RegisterPage.PASSWORD_MUST_CONTAIN_AT_LEAST_3_CHARACTERS"
                      )
                    }}</span>
                  </div>
                </div>
                <div class="row form-row">
                  <div class="col-6 form-group">
                    <label for="new-password" class="form-label"
                      >{{ $t("ProfileSettings.NOUVEAU_MOT_DE_PASSE") }}
                      <span class="text-danger">*</span>:</label
                    >
                    <input
                      id="new-password"
                      type="password"
                      class="form-control"
                      :placeholder="
                        $t('ProfileSettings.NOUVEAU_MOT_DE_PASSE') + '...'
                      "
                      v-model="password"
                      :class="getPasswordClass"
                      required
                    />
                    <span class="invalid-feedback">{{
                      $t(
                        "RegisterPage.PASSWORD_MUST_CONTAIN_AT_LEAST_3_CHARACTERS"
                      )
                    }}</span>
                  </div>
                  <div class="col-6 form-group">
                    <label for="new-confirm-password" class="form-label"
                      >{{ $t("RegisterPage.CORFIRM_PASSWORD") }}
                      <span class="text-danger">*</span>:</label
                    >
                    <input
                      id="new-confirm-password"
                      type="password"
                      class="form-control"
                      :placeholder="$t('RegisterPage.CORFIRM_PASSWORD') + '...'"
                      v-model="confirmPassword"
                      :class="getConfirmPasswordClass"
                      required
                    />
                    <span class="invalid-feedback">{{
                      $t("RegisterPage.MOT_DE_PASSES_DIFFERENTS")
                    }}</span>
                  </div>
                </div>
                <div class="form-row row">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="
                      oldPassword.length < 3 ||
                      password.length < 3 ||
                      password != confirmPassword
                    "
                  >
                    {{ $t("ProfileSettings.APPLIQUER") }}
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      v-show="isPasswordChangeable"
                    ></span>
                  </button>
                </div>
              </form>
              <!-- /Change password form -->
            </div>
          </div>
          <div class="card" v-if="$store.getters.userGroup === 'teacher'">
            <form class="card-body" @submit.prevent="setTeacherProfil">
              <h3>{{ $t("ProfileSettings.PROFIL_ENSEIGNANT") }}</h3>
              <div class="form-group">
                <label for="qualification"></label>
                <input
                  v-model="teacherProfil.qualification"
                  id="qualification"
                  type="text"
                  class="form-control"
                  placeholder="Qualification..."
                />
              </div>
              <div class="form-group">
                <label for="about"></label>
                <textarea
                  id="about"
                  v-model="teacherProfil.about"
                  placeholder="about..."
                  class="form-control"
                  pl
                  rows="5"
                ></textarea>
              </div>
              <button
                :disabled="
                  teacherProfil.about == '' && teacherProfil.qualification == ''
                "
                type="submit"
                class="btn btn-primary"
              >
                {{ $t("ProfileSettings.APPLIQUER") }}
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="isUpdateTeacherProfile"
                ></span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <DashboardFooter />
  </div>
  <!-- /Page Content -->
</template>
<script>
import Navbar from "@/components/layouts/Navbar";
import DashboardFooter from "@/components/layouts/DashboardFooter";
import jwtDecode from "jwt-decode";
import UserSidebar from "../../components/shared/personal/UserSidebar.vue";

export default {
  name: "ProfileSettings",
  data() {
    return {
      user: {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        city: "",
        country: "",
        profilePicture: {
          mediumSquareCrop: "",
          fullSize: "",
          thumbnail: "",
        },
        userPermissions: {},
      },
      loadedImage: null,
      oldPassword: "",
      password: "",
      confirmPassword: "",
      reloadUserInfos: false,
      teacherProfil: {
        qualification: "",
        about: "",
      },
      loading: false,
      isPasswordChangeable: false,
      isProfileUpdatable: false,
      isUpdateTeacherProfile: false,
    };
  },
  components: {
    Navbar,
    UserSidebar,
    DashboardFooter,
  },
  methods: {
    onUserInfosReloaded() {
      this.reloadUserInfos = false;
    },
    async submit() {
      this.isProfileUpdatable = true;
      await this.$http
        .put(`/auth/user/update-profile/${this.user.id}/`, this.user)
        .then(() => {
          this.loadUserData();
          this.isProfileUpdatable = false;
          this.$toast.success("Success!");
        })
        .catch((err) => {
          this.isProfileUpdatable = false;
          this.$toast.error(this.$t("FLASH.UNE_ERREUR_EST_SURVENNU"));
          console.log(err);
        });
    },
    loadUserData() {
      this.$store.dispatch("loadUserInfos").then(() => {
        this.user = JSON.parse(localStorage.getItem("srtingifiedUser"));
        if (this.user.groups === "teacher")
          this.teacherProfil = this.user.teacherProfile;
        this.reloadUserInfos = true;
      });
    },
    setTeacherProfil() {
      this.isUpdateTeacherProfile = true;
      this.$http
        .put(
          `/auth/user/update-teacher-profile/${this.user.id}/`,
          this.teacherProfil
        )
        .then(() => {
          this.isUpdateTeacherProfile = false;

          this.$toast.success("Success");
        })
        .catch(() => {
          this.isUpdateTeacherProfile = false;
        });
    },
    async loadImage(event) {
      this.loading = true;
      this.loadedImage = event.target.files[0];
      const supportedExtensions = ["jpeg", "jpg", "png", "gif"];
      const path = this.loadedImage.name.split(".");
      const extension = `${path[path.length - 1]}`;
      if (supportedExtensions.includes(extension.toLowerCase())) {
        const fd = new FormData();
        fd.append("profile_picture", this.loadedImage, this.loadedImage.name);
        this.$http.put("/auth/user/change-profile-picture/", fd).then(() => {
          this.loadUserData();
          this.$toast.success(this.$t("FLASH.SUCCESS"));
          this.loading = false;
        });
      } else {
        this.$toast.error("Extension non pris en charge");
      }
    },
    changePassword() {
      this.isPasswordChangeable = true;
      this.$http
        .patch(
          `/auth/user/change-password/${
            jwtDecode(localStorage.getItem("access")).user_id
          }/`,
          {
            password: this.password,
            password2: this.confirmPassword,
            old_password: this.oldPassword,
          }
        )
        .then((response) => {
          this.isPasswordChangeable = false;

          if (response == undefined)
            this.$toast.error(this.$t("Ancien mot de passe incorrect"));
          else {
            this.$toast.success(this.$t("ProfileSettings.MOT_DE_PASSE_CHANGE"));
            this.password = "";
            (this.confirmPassword = ""), (this.oldPassword = "");
          }
        })
        .catch((error) => {
          this.isPasswordChangeable = false;
          this.$toast.error(error.response.data.oldPassword.oldPassword);
        });
    },
  },

  beforeMount() {
    this.$nextTick(() => {
      this.loadUserData();
    });
  },
  computed: {
    getFirstNameClass() {
      if (this.user.firstName.length == 0) return "";
      if (this.user.firstName.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getlastNameClass() {
      if (this.user.lastName.length == 0) return "";
      if (this.user.lastName.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    validEMail() {
      return this.$store.state.emailRegex.test(this.user.email);
    },
    getEmailClass() {
      if (this.user.email.length <= 0) return "";
      if (this.validEMail) return "is-valid";
      return "is-invalid";
    },
    getPasswordClass() {
      if (this.password.length == 0) return "";
      if (this.password.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getOldPasswordClass() {
      if (this.oldPassword.length == 0) return "";
      if (this.oldPassword.length < 3) {
        return "is-invalid";
      } else {
        return "is-valid";
      }
    },
    getConfirmPasswordClass() {
      if (this.confirmPassword.length == 0) return "";
      if (this.password != this.confirmPassword) return "is-invalid";
      return "is-valid";
    },
  },
};
</script>
