<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <Navbar />

    <section class="banner-section">
      <div class="container">
        <div class="banner-content text-center">
          <div class="banner-heading">
            <h2 class="h4">
              {{ $t("HomePage.TITRE_DE_PRESENTATION_GENERALE") }}
            </h2>
            <p>{{ $t("HomePage.APPRENEZ_DEPUIS_CHEZ_VOUS") }}</p>
          </div>
          <div class="banner-forms">
            <form class="banner-form">
              <div class="input-group-form form-style form-br col-md-11 col-12">
                <i class="fas fa-book text-warning"></i>
                <input
                  class="input-style-form"
                  type="text"
                  placeholder="Categorie, cours, etc"
                  name="going"
                />
              </div>
            </form>
          </div>
        </div>
        <div class="banner-footer">
          <div class="banner-details">
            <div>
              <div class="banner-card d-flex align-items-center">
                <div class="banner-count">
                  <h2>{{ counts.totalTeachers }}</h2>
                </div>
                <div class="banner-contents">
                  <h2>{{ $t("HomePage.FORMATEURS_GLOBAL") }}</h2>
                </div>
              </div>
            </div>
            <div>
              <div class="banner-card d-flex align-items-center">
                <div class="banner-count">
                  <h2>{{ counts.totalCourses }}</h2>
                </div>
                <div class="banner-contents">
                  <h2>{{ $t("HomePage.PROGRAMMES_DE_COURS") }}</h2>
                </div>
              </div>
            </div>
            <div>
              <div class="banner-card d-flex align-items-center">
                <div class="banner-count">
                  <h2>{{ counts.totalStudents }}</h2>
                </div>
                <div class="banner-contents">
                  <h2>{{ $t("HomePage.ETUDIANTS_GLOBAL") }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Banner -->

    <!-- All Courses -->
    <section class="allcourse-section bg-grey">
      <div class="container">
        <div class="row">
          <div
            v-for="c in categories.slice(0, 6)"
            :key="c.id"
            class="col-md-4 col-lg-4 d-flex flex-wrap"
          >
            <div class="allcourse-card">
              <div class="allcourse-img">
                <img
                  :src="c.categoryPicture.mediumSquareCrop"
                  :alt="c.name"
                  class="img-fluid"
                />
              </div>
              <div class="allcourse-content">
                <h4 class="mb-3">{{ c.name }}</h4>
                <p>
                  {{ $t("HomePage.OVER") }}
                  <span class="text-warning">{{
                    numberFormat(c.categoryCourses.length)
                  }}</span>
                  {{ $t("HomePage.COURSES") }}
                </p>
              </div>
            </div>
          </div>
          <div class="section-btn m-auto">
            <button
              @click="$router.push({ name: 'Courses' })"
              class="btn btn-course"
            >
              {{ $t("HomePage.VIEW_ALL_COURSE") }}
              <i class="fas fa-caret-right right-nav-white"></i>
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- /All Courses -->

    <!-- Popular Courses -->
    <section class="popular-course-section">
      <div class="container">
        <div class="section-heading d-flex align-items-center">
          <div class="heading-content">
            <h2>
              <span class="text-weight">{{
                $t("HomePage.POPULAR_COURSES")
              }}</span>
              <span class="header-right"></span>
            </h2>
            <p>
              {{ $t("HomePage.FORMATIONS_POPULAIRES") }}
            </p>
          </div>
        </div>
        <div class="row" v-if="!loading">
          <ArticleCourseComponent
            :course="c"
            v-for="c in popularCourses"
            :key="c.id"
          />
        </div>
        <div v-if="loading" class="row">
          <CourseLoader v-for="i in 4" :key="i" />
        </div>
      </div>
    </section>
    <!-- /Popular Courses -->
    <!-- Popular Courses -->
    <section class="popular-course-section">
      <div class="container">
        <div class="section-heading d-flex align-items-center">
          <div class="heading-content">
            <h2>
              <span class="text-weight"> {{ $t("HomePage.NEW_COURSES") }}</span>
              <span class="header-right"></span>
            </h2>
            <p>
              {{ $t("HomePage.NOUVELLES_FORMATIONS") }}
            </p>
          </div>
        </div>
        <div class="row" v-if="!loading">
          <ArticleCourseComponent
            :course="c"
            v-for="(c, i) in newCourse"
            :key="i"
          />
        </div>
        <div v-if="loading" class="row">
          <CourseLoader v-for="i in 4" :key="i" />
        </div>
      </div>
    </section>
    <!-- /Popular Courses -->

    <!-- Payment -->
    <section class="blog-sections">
      <div class="container">
        <div class="section-heading d-flex align-items-center">
          <div class="heading-content">
            <h2>
              <span class="text-weight"
                >{{ $t("HomePage.PAYMENT_MEANS") }}
              </span>
              <span class="header-right"></span>
            </h2>
            <p>
              {{ $t("HomePage.PAYMENT_MEANS_DESC") }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="blog-box blog-grid-box text-center h-336">
              <div class="blog-grid-box-img">
                <img
                  :src="require('@/assets/img/mobilemoney.png')"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="blog-grid-box-content">
                <h4 class="font-weight-bold">Mobile Money</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="blog-box blog-grid-box text-center h-336 m-auto">
              <div class="blog-grid-box-img">
                <img
                  :src="require('@/assets/img/visa.jpg')"
                  class="img-fluid"
                  alt=""
                />
              </div>
              <div class="blog-grid-box-content">
                <h4 class="font-weight-bold">
                  {{ $t("HomePage.INTERNATIONAL_PAYMENT") }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Payment -->
    <!-- Blogs -->
    <section class="blog-sections">
      <div class="container">
        <div class="section-heading d-flex align-items-center">
          <div class="heading-content">
            <h2>
              <span class="text-weight">{{ $t("HomePage.A_PROPOS") }} </span>
              <span class="header-right"></span>
            </h2>
            <p>
              {{ $t("HomePage.A_PROPOS_DESC") }}
            </p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12 mx-auto">
            <div class="blog-box blog-grid-box text-center h-336 m-auto p-2">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://player.vimeo.com/video/639942652?h=d7e60cf25b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  title="e-pub~1.mp4"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="blog-sections">
      <div class="container">
        <div class="section-heading d-flex align-items-center">
          <div class="heading-content">
            <h2>
              <span class="text-weight"
                >{{ $t("HomePage.PRICE_AND_PARTNER") }}
              </span>
              <span class="header-right"></span>
            </h2>
            <p>
              {{ $t("HomePage.PRICE_AND_PARTNER_DESC") }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mx-auto">
            <div class="blog-box blog-grid-box text-center h-336 m-auto">
              <div class="blog-grid-box-img">
                <a target="_blank" href="https://agrohelp.net">
                  <img
                    src="https://eparcheminapi-medias.s3.us-east-2.amazonaws.com/assets/agrohelp_2_25.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div class="blog-grid-box-content">
                <h4>{{ $t("HomePage.OUR_PARTNERS") }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Blogs -->

    <!-- Footer -->
    <Footer />
    <!-- End Footer -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/layouts/Navbar";
import Footer from "@/components/layouts/Footer";
import { APIUrl } from "@/http";
import numeral from "numeral";
import ArticleCourseComponent from "../components/shared/courses/ArticleCourseComponent.vue";
import CourseLoader from "@/components/shared/courses/CourseLoader.vue";

export default {
  name: "Home",
  data() {
    return {
      newCourse: [],
      popularCourses: [],
      apiUrl: APIUrl,
      counts: {
        totalStudents: 1000,
        totalCourses: 1000,
        totalTeachers: 1000,
      },
      categories: [],
      loading: false,
    };
  },
  components: {
    Navbar,
    Footer,
    ArticleCourseComponent,
    CourseLoader,
  },
  methods: {
    loadNewsCourses() {
      this.$store.dispatch("loadMostCourses").then((mostCourses) => {
        this.newCourse = mostCourses.newCourse;
        this.popularCourses = mostCourses.popularCourses;
        this.loading = false;
      });
    },
    loadCounts() {
      this.loading = true;
      this.$store.dispatch("loadCounts").then((counts) => {
        this.counts = counts;
      });
    },
    numberFormat(n) {
      return numeral(n).format("0,0");
    },
    loadAllCathegories() {
      this.$store
        .dispatch("loadAllCathegoriesCourses")
        .then((categories) => (this.categories = categories));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCounts();
      this.loadAllCathegories();
      this.loadNewsCourses();
    });
  },
};
</script>
<style scoped>
.h-336 {
  min-height: 336px;
}
.banner-heading h2 {
  font-size: 31px !important;
  font-weight: bold;
}
.banner-heading p {
  font-size: 21px !important;
  margin-top: 25px;
  font-style: italic;
}
@media only screen and (max-width: 750px) {
  .banner-heading h2 {
    font-size: 17px !important;
    font-weight: bold;
  }

  .banner-heading p {
    font-size: 15px !important;
  }

  /* .banner-forms .form-br {
    display: none;
  } */
  .banner-footer .banner-contents h2 {
    font-size: 22px;
  }
  .h-336 {
    min-height: 200px;
  }

  .blog-sections .heading-content h2 {
    font-size: 25px !important;
    text-align: center;
  }
}

@media only screen and (max-width: 300px) {
  .banner-heading h2 {
    font-size: 13px !important;
    font-weight: bold;
  }

  .banner-heading p {
    font-size: 10px !important;
  }

  .banner-forms .form-br {
    display: none;
  }
  .banner-footer .banner-contents h2 {
    font-size: 15px;
  }

  .banner-footer .banner-count h2 {
    font-size: 20px;
  }

  .popular-course-section .heading-content h2 {
    font-size: 20px !important;
    text-align: center !important;
  }

  .blog-sections .heading-content h2 {
    font-size: 15px !important;
    text-align: center !important;
  }
  .h-336 {
    min-height: 200px;
  }
}
</style>
