<template lang="">
  <div class="col-lg-3 col-md-4 col-sm-12 col-12 skeleton"></div>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
@media only screen and (min-width: 765px) {
  .skeleton {
    max-width: 208px;
    margin: 10px;
  }
}
@media only screen and (min-width: 1040px) {
  .skeleton {
    max-width: 198px;
  }
}
@media only screen and (min-width: 1172px) {
  .skeleton {
    max-width: 233px;
  }
}
@media only screen and (max-width: 742px) {
  .skeleton {
    width: 100%;
    max-width: 100%;
  }
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 0.6s infinite alternate;
  min-height: 250px;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
