<template>
  <!-- Page Content -->
  <div class="bg-pattern-style">
    <div class="content">
      <!-- Login Tab Content -->
      <div class="account-content">
        <div class="account-box">
          <div class="login-right">
            <img
              :src="require('@/assets/img/logos/logo.png')"
              class="img-fluid auth-img"
              alt="Logo"
            />
            <div class="login-header">
              <h3>
                Login
                <span>
                  <router-link :to="{ name: 'Home' }">Eparchemin</router-link>
                </span>
              </h3>
            </div>
            <form @submit.prevent="login()">
              <p class="alert alert-danger" v-if="wrongCredntials">
                {{ $t("Login.IDENTIFIANTS_INCORRECTS") }}
              </p>
              <div class="form-group">
                <label class="form-control-label">Email</label>
                <input
                  autofocus
                  type="email"
                  v-model="email"
                  class="form-control "
                  :class="getEmailClass"
                  placeholder="Email..."
                />
                <span class="invalid-feedback">{{
                  $t("Login.EMAIL_INVALIDE")
                }}</span>
              </div>
              <div class="form-group">
                <label class="form-control-label">{{
                  $t("Login.MOT_DE_PASSE")
                }}</label>
                <div class="pass-group">
                  <input
                    type="password"
                    id="password"
                    v-model="password"
                    class="form-control pass-input"
                    :class="getPasswordClass"
                    :placeholder="$t('Login.MOT_DE_PASSE') + '...'"
                  />
                  <span
                    @click="togglePassordField()"
                    class="fas fa-eye toggle-password"
                  ></span>
                  <span class="invalid-feedback">{{
                    $t("Login.MOT_DE_PASSE_AU_MOINS_3")
                  }}</span>
                </div>
              </div>
              <div class="text-right">
                <router-link
                  class="forgot-link"
                  :to="{ name: 'PasswordRecovery' }"
                  >{{ $t("Login.MOT_DE_PASSE_OUBLIE") }}</router-link
                >
              </div>
              <button
                :disabled="email == '' || password == '' || !validEMail"
                class="btn btn-primary login-btn"
                type="submit"
              >
                {{ $t("Login.SE_CONNECTER") }}
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="isSending"
                ></span>
              </button>
              <div class="text-center dont-have">
                {{ $t("Login.PAS_DE_COMPTE")






                }}<router-link :to="{ name: 'Register' }">{{
                  $t("Login.CREER_COMPTE")
                }}</router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- /Login Tab Content -->
    </div>
  </div>
  <!-- /Page Content -->
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      wrongCredntials: false,
      validInputEmail: true,
      isSending: false,
    };
  },
  methods: {
    async login() {
      this.isSending = true;
      await (this.$http
        .post("/auth/login/", { email: this.email, password: this.password })
        .then((res) => {
          this.$store.commit("updateLocalStorage", res.data);
          this.$store.dispatch("startRefreshTokenTimer");
        })
        .catch((err) => {
          this.isSending = false;
          if (err.response && err.response.status == 401) {
            this.$toast.error(this.$t("Login.IDENTIFIANTS_INCORRECTS"));
            this.password = ''
          } else {
            this.$toast.error(
              "Une erreur est survennu, veillez resseyer plus tard"
            );
          }
        }))
        .then(() => {
          this.$store.dispatch("loadUserInfos").then(() => {
            this.$store.commit("updateUserState");
            this.wrongCredntials = false;
            this.$toast.success(this.$t("Login.LOGIN_SUCCESS"));
            let nextUrl = localStorage.getItem("nextUrl");
            if (nextUrl != null) {
              nextUrl = JSON.parse(nextUrl);
              localStorage.removeItem(nextUrl);
              this.$router.push(nextUrl);
            } else if (
              this.$store.getters.userGroup === "teacher" ||
              this.$store.getters.userGroup === "student"
            )
              this.$router.push({ name: "Dashboard" });
            else this.$router.push({ name: "ProfileSettings" });
          });
          this.isSending = false;
        })
        
    },
    togglePassordField() {
      let pwdf = document.getElementById("password");
      if (pwdf.type == "password") pwdf.type = "text";
      else pwdf.type = "password";
    },
  },
  computed: {
    validEMail() {
      return this.$store.state.emailRegex.test(this.email);
    },
    getEmailClass() {
      if (this.email.length <= 0) return "";
      if (this.validEMail) return "is-valid";
      return "is-invalid";
    },
    getPasswordClass() {
      if (this.password.length == 0) return "";
      if (this.password.length < 3) return "is-invalid";
      return "";
    },
  },
};
</script>
<style lang="css">
.login-right {
  position: relative;
}
@media only screen and (max-width: 992px) {
  .auth-img {
    position: absolute;
    height: 60px;
    width: 60px;
    top: -30px;
    left: 40%;
    border-radius: 50%;
  }
}
@media only screen and (min-width: 992px) {
  .auth-img {
    position: absolute;
    height: 80px;
    width: 80px;
    top: -80px;
    left: 42%;
    border-radius: 50%;
  }
}
</style>
