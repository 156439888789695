import { createStore } from "vuex";
import { getAPI } from "./../http";
import jwtDecode from "jwt-decode";

export default createStore({
  state: {
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    accessToken: localStorage.getItem("access") || null,
    refreshToken: localStorage.getItem("refresh") || null,
    logedUser:
      localStorage.getItem("srtingifiedUser") != null
        ? JSON.parse(localStorage.getItem("srtingifiedUser"))
        : {
            firstName: "",
            lastName: "",
            email: "",
            userGroup: "",
          },
    refreshTokenTimer: null,
    allEnrollments: null,
    allCateCourses: null,
    teacherDashBoard: null,
    allCategories: null,
    paginatedCourses: null,
    mostCourses: {
      newCourse: null,
      popularCourses: null,
    },
    pageCourse: 1,
    totalPages: 1,
    pageSize: 16,
    defaultCurrency: localStorage.getItem("currency") || "XAF",
    lang: localStorage.getItem("lang") || "fr",
    counts: {
      totalStudents: null,
      totalCourses: null,
      totalTeachers: null,
    },
  },

  getters: {
    loggedIn(state) {
      return state.accessToken != null;
    },
    userGroup(state) {
      if (state.logedUser == null) return null;
      return state.logedUser.groups;
    },
    sufixUrl(state) {
      return `currency=${state.defaultCurrency}`;
    },
  },
  mutations: {
    setDefaultCurrency(state, currency) {
      state.defaultCurrency = currency;
    },
    changeLanguage(state, lang) {
      state.lang = lang;
      localStorage.setItem("lang", lang);
    },
    updateLocalStorage(state, data) {
      localStorage.setItem("access", data.tokens.access);
      localStorage.setItem("refresh", data.tokens.refresh);
      localStorage.setItem("email", data.email);
      state.accessToken = data.access;
      state.refreshToken = data.refresh;
      state.logedUser.email = data.email;
      state.logedUser.firstName = data.firstName;
      state.logedUser.lastName = data.lastName;
    },
    updateUserState(state) {
      if (localStorage.getItem("srtingifiedUser") != null)
        state.logedUser = JSON.parse(localStorage.getItem("srtingifiedUser"));
    },
    updateAccess(state, access) {
      localStorage.setItem("access", access);
      state.accessToken = access;
    },
    destroyToken(state) {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("srtingifiedUser");
      state.accessToken = null;
      state.refreshToken = null;
      state.logedUser = {
        firstName: "",
        lastName: "",
        email: "",
        userGroup: "",
      };
    },
  },
  actions: {
    async refreshToken(context) {
      await getAPI
        .post("/auth/token/refresh/", {
          refresh: localStorage.getItem("refresh"),
        })
        .then((response) => {
          context.commit("updateAccess", response.data.access);
          context.dispatch("startRefreshTokenTimer");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout(context) {
      return new Promise((resolve) => {
        localStorage.clear();
        context.commit("destroyToken");
        context.dispatch("deleteAutorizationHeader");
        context.dispatch("stopRefreshTokenTimer");
        resolve();
      });
    },
    deleteAutorizationHeader() {
      delete getAPI.defaults.headers.common["Authorization"];
    },
    startRefreshTokenTimer(context) {
      if (context.state.refreshTokenTimer != null)
        context.dispatch("stopRefreshTokenTimer");
      const jwtToken = jwtDecode(localStorage.getItem("access"));
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;
      context.state.refreshTokenTimer = setTimeout(
        () => context.dispatch("refreshToken"),
        timeout
      );
    },
    stopRefreshTokenTimer(context) {
      clearTimeout(context.state.refreshTokenTimer);
      context.state.refreshTokenTimer = null;
    },
    async loadUserInfos(context) {
      const token = localStorage.getItem("access");
      let config = {};
      if (token !== null)
        config = { headers: { Authorization: `Bearer ${token}` } };
      return getAPI.get("/auth/user/infos/", config).then((response) => {
        let user = response.data;
        // user.profilePicture.mediumSquareCrop = APIUrl + user.profilePicture.mediumSquareCrop;
        // user.profilePicture.fullSize = APIUrl + user.profilePicture.fullSize;
        // user.profilePicture.thumbnail = APIUrl + user.profilePicture.thumbnail;
        const userStringified = JSON.stringify(user);
        localStorage.setItem("srtingifiedUser", userStringified);
        context.commit("updateUserState");
      });
    },
    async loadAllEnrollments(context) {
      if (context.state.allEnrollments === null)
        await getAPI
          .get(
            `/enrolment/users/all-active-course?currency=${context.state.defaultCurrency}`
          )
          .then((response) => {
            context.state.allEnrollments = response.data;
          });
      return new Promise((resolve) => {
        return resolve(context.state.allEnrollments);
      });
    },
    async loadAllCathegoriesCourses(context) {
      if (context.state.allCateCourses === null) {
        await getAPI
          .get(
            `/courses/category/all?currency=${context.state.defaultCurrency}`
          )
          .then((res) => (context.state.allCateCourses = res.data));
      }
      return new Promise(
        (resolve) => resolve(context.state.allCateCourses),
        (reject) => reject()
      );
    },
    async loadAllCategories(context) {
      if (context.state.allCategories === null) {
        await getAPI.get(`/courses/category`).then((response) => {
          context.state.allCategories = response.data;
        });
      }
      return new Promise(
        (resolve) => resolve(context.state.allCategories),
        (reject) => reject()
      );
    },
    async loadAllCourses(context) {
      if (context.state.paginatedCourses === null) {
        await getAPI
          .get(
            `/courses/list/?page=${context.state.pageCourse}&page_size=${context.state.pageSize}`
          )
          .then((response) => {
            context.state.paginatedCourses = response.data;
          });
        return new Promise(
          (resolve) => resolve(context.state.paginatedCourses.result),
          (reject) => reject()
        );
      }
    },
    async loadTeacherDashBoard(context) {
      if (context.state.teacherDashBoard === null)
        await getAPI
          .get(
            `/auth/teacher/course-list/${
              jwtDecode(context.state.accessToken).user_id
            }/`
          )
          .then((response) => (context.state.teacherDashBoard = response.data));
      return new Promise(
        (resolve) => resolve(context.state.teacherDashBoard),
        (reject) => reject()
      );
    },
    async loadMostCourses(context) {
      if (context.state.mostCourses.newCourse === null) {
        await getAPI
          .get(
            `/courses/best-and-new-courses?currency=${context.state.defaultCurrency}`
          )
          .then((response) => {
            context.state.mostCourses.newCourse = response.data.newCourse;
            context.state.mostCourses.popularCourses =
              response.data.popularCourses;
          });
      }
      return new Promise(
        (resolve) => resolve(context.state.mostCourses),
        (reject) => reject()
      );
    },
    async loadCounts(context) {
      if (context.state.counts.totalStudents === null) {
        await getAPI
          .get("/enrolment/global-infos")
          .then((response) => (context.state.counts = response.data));
      }
      return new Promise((resolve) => resolve(context.state.counts));
    },

    async changeCoursePage(context, direction) {
      const sufixUrl = `&${context.getters.sufixUrl}`;
      if (context.state.paginatedCourses === null || direction === null) {
        context.state.pageCourse = 1;
        await getAPI
          .get(`/courses/list/?page_size=${context.state.pageSize + sufixUrl}`)
          .then((response) => {
            context.state.paginatedCourses = response.data;
            context.state.totalPages = Math.ceil(
              response.data.count / context.state.pageSize
            );
          });
      } else {
        if (
          direction === "next" &&
          context.state.paginatedCourses.next !== null
        ) {
          context.state.pageCourse++;
          await getAPI
            .get(context.state.paginatedCourses.next + sufixUrl)
            .then(
              (response) => (context.state.paginatedCourses = response.data)
            );
        } else if (context.state.paginatedCourses.previous !== null) {
          context.state.pageCourse--;
          await getAPI
            .get(context.state.paginatedCourses.previous + sufixUrl)
            .then(
              (response) => (context.state.paginatedCourses = response.data)
            );
        }
      }
      return new Promise((resolve) => resolve(context.state.paginatedCourses));
    },
  },
  modules: {},
});
