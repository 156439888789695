<template lang="">
  <div>
    <div class="card card-table">
      <div class="card-body">
        <div v-if="enrolments !== null && enrolments.length == 0">
          <p class="alert alert-warning">
            {{ $t("StudentDashboard.PAS_DE_COURS") }}
          </p>
          <router-link :to="{ name: 'Courses' }">
            {{ $t("StudentDashboard.CONSULTEZ_LES_COURS") }}
          </router-link>
        </div>
        <div
          class="table-responsive"
          v-if="enrolments !== null && enrolments.length > 0"
        >
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>{{ $t("StudentDashboard.COURS") }}</th>
                <th>{{ $t("Dashboard.PRIX") }}</th>
                <th>{{ $t("StudentDashboard.PROGRESSION") }}</th>
                <th>{{ $t("StudentDashboard.DEBUT") }}</th>
                <th>{{ $t("StudentDashboard.FIN") }}</th>
                <th class="text-center">ACTION</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="e in enrolments" :key="e.id">
                <td>
                  <h2 class="table-avatar">
                    <router-link
                      :to="{
                        name: 'ShowCourseDetails',
                        params: { idCourse: e.course.id },
                      }"
                      class="avatar avatar-sm mr-2"
                      ><img
                        class="avatar-img rounded-circle"
                        :src="e.course.coursePicture.thumbnail"
                        :alt="e.title"
                    /></router-link>
                    <router-link
                      :to="{
                        name: 'ShowCourseDetails',
                        params: { idCourse: e.course.id },
                      }"
                      >{{ e.course.title }}
                      <span
                        ><span class="__cf_email__"
                          >{{ e.totalContent }}
                          {{ $t("StudentDashboard.VIDEOS") }}</span
                        ></span
                      ></router-link
                    >
                  </h2>
                </td>
                <td>
                  {{ formatPrice(e.price) }}
                  <strong>{{ e.currency }}</strong>
                </td>
                <td>{{ (e.progression / e.totalContent) * 100 }}%</td>
                <td>
                  {{ e.startDate }}
                </td>
                <td>
                  {{ e.endDate }}
                </td>
                <td class="text-center">
                  <router-link
                    v-if="e.userStatusInCours == 'active'"
                    :to="{
                      name: 'ShowCourse',
                      params: { idCourse: e.course.id },
                    }"
                    class="btn btn-sm bg-info-light"
                    ><i class="fas fa-book-reader"></i>
                    {{ $t("StudentDashboard.ACCEDER") }}</router-link
                  >
                  <router-link
                    v-if="e.progression / e.totalContent == 1"
                    :to="{
                      name: 'ShowCourse',
                      params: { idCourse: e.course.id },
                    }"
                    class="btn btn-sm bg-success-light ml-1"
                    ><i class="fas fa-trophy"></i>
                    {{ $t("StudentDashboard.CERTIFICAT") }}</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import numeral from "numeral";
import { APIUrl } from "@/http";

export default {
  data() {
    return {
      enrolments: null,
      api: APIUrl,
    };
  },
  methods: {
    async loadStudentEnrolments() {
      await this.$http
        .get("/enrolment/users/all-active-course")
        .then((response) => {
          this.enrolments = response.data;
        });
    },
    formatPrice(p) {
      return numeral(p).format("0,0");
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadStudentEnrolments();
    });
  },
};
</script>
<style lang=""></style>
